<template>
  <div class="head">
    <ul>
      <li v-if="props.noHomePage" class="backMv" @click="backMv">返回</li>
      <li v-else-if="props.back" class="home" @click="backTop">回顶</li>
      <li
        :class="{ now: props.now == true }"
        class="dianYing"
        @click="backHome"
      >
        电影
      </li>
      <li class="juJi" :class="{ now: props.now == false }" @click="backtv">
        剧集
      </li>
    </ul>
  </div>
</template>

<script setup>
import router from '@/router/index.js'
const height = document.documentElement.scrollTop || document.body.scrollTop
const props = defineProps({
  noHomePage: { type: Boolean, default: false },
  now: { type: Boolean, default: true },
  back: { type: Boolean, default: false }
})

const backMv = () => {
  router.back()
}
const backHome = () => {
  router.push('/')
}
const backTop = () => {
  window.scrollTo(0, height)
}
const backtv = () => {
  router.push('/dramas')
}
</script>

<style lang="scss" scoped>
.head {
  width: 100%;
  background-color: #1b2434;
  height: 40px;
  margin: 0 0 0 0;
  padding: 1% 0 0 0;
  align-self: start;
  overflow: auto;
  position: fixed;
  z-index: 2;
  ul {
    display: flex;
    list-style: none;
    padding-inline-start: 6%;
    margin: 0;
    li {
      width: 80px;
      font-size: 20px;
      font-weight: bolder;
      color: #9fa0a1;
    }
    .backMv:hover {
      color: rgba(137, 141, 102);
      cursor: pointer;
    }
    .dianYing:hover {
      color: rgba(137, 141, 102);
      cursor: pointer;
    }
    .juJi:hover {
      color: rgba(137, 141, 102);
      cursor: pointer;
    }
    .home:hover {
      color: rgba(137, 141, 102);
      cursor: pointer;
    }
    .now {
      color: rgba(137, 141, 102);
    }
  }
}
</style>
