<template>
  <MyNavbar :noHomePage="true" :now="now" />
  <div class="dataPage">
    <div class="plistTop"></div>
    <div class="detail">
      <div class="poster" @click="goBottom">
        <img :src="id.poster || store.state.MDB.poster" />
      </div>
      <div class="mvinfo">
        <h1>
          {{ id.name || store.state.MDB.name }}
        </h1>
        <p><span>类型: </span>{{ store.state.MDB.tag }}</p>
        <p><span>导演: </span>{{ store.state.MDB.director }}</p>
        <p><span>主演: </span>{{ store.state.MDB.actor }} ...</p>
        <p><span>上映时间: </span>{{ store.state.MDB.date }}</p>
        <p>
          <span>简介:</span>
          {{ store.state.MDB.synopsis }}
        </p>
      </div>
    </div>
    <div>
      <div class="plist"><p>播放列表</p></div>
      <MyPlaylist :minfo="minfo" />
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect, onMounted } from 'vue'
import MyNavbar from '@/components/MyNavbar.vue'
import MyPlaylist from '@/components/MyPlaylist.vue'
import axios from 'axios'
import store from '@/store/index.js'
import { useRouter } from 'vue-router'
// const synopsis = ref()
const router = useRouter()
const id = ref()
const now = ref()
const tve = ref()
if (sessionStorage.getItem('tve') === 'undefined') {
  tve.value = false
} else {
  tve.value = true
}

id.value = router.currentRoute.value.params

const minfo = ref()

const goBottom = () => {
  window.scrollTo(0, document.documentElement.clientHeight)
}

// const play = () => {
//   router.push({
//     name: 'player',
//     params: {
//       name: store.state.MDB.name,
//       url: store.state.MDB.url
//     }
//   })
// }

if (sessionStorage.MDB) {
  store.dispatch('loadMDB')
  minfo.value = store.state.MDB
}

if (tve.value) {
  now.value = false
  // 请求电视剧详情
  axios
    .get(
      // 'http://192.168.3.254:4510/api/tvdetail/',
      'https://v.189289.xyz:1122/get/api/tvdetail/',
      {
        params: {
          id: id.value.id || store.state.MDB._id
        }
      }
    )
    .then((res) => {
      minfo.value = res.data
      store.commit('setMDB', minfo.value)
    })

    .catch((err) => {
      throw err
    })
} else {
  now.value = true

  axios
    // 请求电影详情
    .get(
      // 'http://192.168.3.254:4510/api/detail/',
      'https://v.189289.xyz:1122/get/api/detail/',
      {
        params: {
          id: id.value.id || store.state.MDB._id
        }
      }
    )
    .then((res) => {
      // console.log(res.data)
      // minfo.value = []   // 分页时key值报错需要预先清除
      minfo.value = res.data
      store.commit('setMDB', minfo.value)
    })

    .catch((err) => {
      throw err
    })
}

onMounted(() => {
  // 屏蔽选择元素上的右键菜单
  document.querySelector('.poster').oncontextmenu = () => {
    return false
  }
})
watchEffect(() => {
  if (id.value.episode) {
    now.value = false
  } else if (store.state.MDB.urls) {
    now.value = false
  } else {
    now.value = true
  }
})
</script>

<style lang="scss">
.text-with-newline {
  white-space: pre-line;
}
.dataPage {
  display: flex;
  flex-direction: column;
  padding: 1% 5% 4% 5%;
  margin-top: 0%;
  margin-bottom: 0;
  .detail {
    display: flex;
    @media (max-width: 600px) {
      margin-top: 10%;
      flex-direction: column;
      align-items: center;
    }
    .poster {
      width: 35%;
      height: 100%;
      min-width: 250px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .mvinfo {
      @media (max-width: 600px) {
        margin: 0;
        padding: 0;
        font-size: 12px;
      }
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      white-space: pre-wrap;
      margin-left: 1%;
      h1 {
        color: #e6e7fe;
        margin: 0;

        @media (max-width: 600px) {
          text-align: center;
        }
      }
      p {
        margin: 5px 0 5px 0;
        line-height: 1.8;
      }
      span {
        font-weight: bold;
        color: #8cd4ff;
      }
    }
  }
  // .play {
  //   display: inline-block;
  //   border-style: solid;
  //   border-color: #2c3c56;
  //   height: 25px;
  //   line-height: 25px;
  //   width: 90px;
  //   align-items: center;
  //   justify-content: center;
  //   text-align: center;
  //   color: #fff;
  //   text-decoration: none;
  // }
  // .play:hover {
  //   border-color: rgba(137, 141, 102, 0.76);
  //   cursor: pointer;
  // }
}
.plistTop {
  border-bottom-style: dotted;
  border-color: #465d83;
  border-width: 1px;
  margin-bottom: 1%;
  margin-top: 3%;
}
.plist {
  border-bottom-style: dotted;
  border-color: #465d83;
  border-width: 1px;
  margin-bottom: 1%;
}
</style>
