<template>
  <router-view />
</template>

<script setup></script>

<style lang="scss">
html,
body,
#app {
  background-color: #1b2434;
  color: #f8fafc;
  margin: 0;
  padding: 0;
}
</style>
