<template>
  <MyNavbar :noHomePage="true" :now="!istv" />
  <div class="playerPage">
    <div class="mobpage">
      <h2>
        {{ router.currentRoute.value.params.name || store.state.MDB.name }}
        <span v-if="istv">{{ store.state.tvnow.nowtvKey }}</span>
      </h2>
    </div>
    <div class="player">
      <div id="dplayer"></div>
    </div>

    <div v-if="istv" class="pcard">
      <!-- <div class="plist"><p>播放列表</p></div> -->
      <TvPagelist :tvinfo="tvinfo" @play="play" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import store from '@/store/index.js'
import MyNavbar from '@/components/MyNavbar.vue'
import TvPagelist from '@/components/TvPagelist.vue'
import DPlayer from 'dplayer'

const dp = ref()
const istv = ref(false)
const router = useRouter()
const tvinfo = ref({})
const DpWidth = ref()
const DpHeight = ref()
// const playY = ref()
// const playVolume = ref()

// 接受路由params对象传参。router.currentRoute.value.params
// console.log(router.currentRoute.value.params)
store.dispatch('loadtvnow')
store.dispatch('loadMDB')
const time = ref()
const playUrl = ref(0)

if (document.body.clientWidth <= 600) {
  DpWidth.value = document.body.clientWidth + 'px'
  DpHeight.value = document.body.clientWidth * 0.75 + 'px'
} else {
  DpWidth.value = document.body.clientWidth * 0.7 + 'px'
  DpHeight.value = document.body.clientWidth * 0.7 * 0.563 + 'px'
}

const initPlayer = (dpurl, dptime) => {
  dp.value = new DPlayer({
    container: document.getElementById('dplayer'),
    autoplay: true,
    theme: '#409eff',
    loop: false,
    lang: 'zh-cn',
    hotkey: true,
    preload: 'auto',
    volume: 1,
    mutex: true,
    // logo: "https://i.loli.net/2019/06/06/5cf8c5d94521136430.png",
    video: {
      url: dpurl
    }
    // 外挂字幕
    // subtitle: {
    //   url: 'dplayer.vtt',
    //   type: 'ass',
    //   fontSize: '25px',
    //   bottom: '10%',
    //   color: '#b7daff'
    // }
    // 弹幕
    // danmaku: {
    // id: store.state.MDB._id
    // api: 'http://192.168.3.13:4510/api/dan',
    // user: 'guest',
    // bottom: '15%',
    // unlimited: true,
    // speedRate: 0.5
    // }
    // 自定义右键菜单
    // contextmenu: [
    //   {
    //     text: "自定义菜单",
    //     click: (player) => {
    //       console.log(player);
    //     },
    //   },
    // ],
  })
  // 事件绑定
  // ???IOS无法锁定屏幕方向,尚未找到有效解决方案
  if (/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
    dp.value.on('timeupdate', function () {
      time.value = dp.value.video.currentTime
      localStorage.setItem(
        String(store.state.MDB._id) + String(store.state.tvnow.nowtvKey),
        Math.floor(dp.value.video.currentTime)
      )
    })
  } else {
    dp.value.on('fullscreen', function () {
      screen.orientation.lock('landscape')
    })

    dp.value.on('fullscreen_cancel', function () {
      screen.orientation.unlock()
    })
    dp.value.seek(dptime)
    dp.value.on('timeupdate', function () {
      localStorage.setItem(
        String(store.state.MDB._id) + String(store.state.tvnow.nowtvKey),
        Math.floor(dp.value.video.currentTime)
      )
    })
  }
}
// 接收子组件参数
const play = (key, item) => {
  dp.value.destroy()
  const temp = {
    nowtvKey: key,
    nowtvurl: item
  }
  store.commit('settvnow', temp)
  initPlayer(
    item,
    localStorage.getItem(
      String(store.state.MDB._id) + String(store.state.tvnow.nowtvKey)
    )
  )
}

// 禁用F12
// document.addEventListener('keydown', (e) => {
//   if (e.key === 'F12') {
//     // window.event.cancelBubble = true
//     e.preventDefault()
//   }
// })

// 播放广告通过 video事件 控制多个视频轮播完成

onBeforeMount(() => {
  time.value = localStorage.getItem(
    String(store.state.MDB._id) + String(store.state.tvnow.nowtvKey)
  )
  if (router.currentRoute.value.params.nowUrl1) {
    istv.value = true
    playUrl.value = router.currentRoute.value.params.nowUrl
    tvinfo.value = store.state.MDB.urls
  } else if (store.state.tvnow.nowtvurl) {
    istv.value = true
    playUrl.value = store.state.tvnow.nowtvurl
    tvinfo.value = store.state.MDB.urls
  } else {
    istv.value = false
    playUrl.value = store.state.MDB.url
  }
})
onMounted(() => {
  initPlayer(playUrl.value, time.value)
  // 移动端隐藏操作界面
  dp.value.on('playing', function () {
    dp.value.controller.setAutoHide()
  })
  dp.value.container.addEventListener('mousemove', () => {
    dp.value.controller.setAutoHide()
  })
  dp.value.container.addEventListener('click', () => {
    dp.value.controller.setAutoHide()
  })
  // 屏蔽原生video右键菜单
  document.querySelector('#dplayer').oncontextmenu = () => {
    document.querySelector('.dplayer-menu').style.display = 'none'
    document.querySelector('.dplayer-mask').style.display = 'none'
    return false
  }
  // ios端 playing 中实现seek 就可以解决ios端问题
  if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    if (time.value) {
      dp.value.on('playing', function () {
        dp.value.seek(time.value)
      })
    }
  }
  if (/Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent)) {
    // 移动端提醒
    dp.value.notice('0权限,请手动调节音量及亮度等', 5000)
  }
  // 手势,web无权限控制手机音量亮度等
  // dp.value.container.addEventListener('touchstart', (e) => {
  //   playY.value = e.changedTouches[0].pageY
  //   playVolume.value = document.querySelector('.dplayer-video').volume
  // })
  // dp.value.container.addEventListener('touchmove', (e) => {
  //   if (e.changedTouches[0].pageY < playY.value) {
  //     playY.value = e.changedTouches[0].pageY
  //     dp.value.volume((playVolume.value += 0.02), true, false)
  //   }
  //   if (e.changedTouches[0].pageY > playY.value) {
  //     playY.value = e.changedTouches[0].pageY
  //     dp.value.volume((playVolume.value -= 0.02), true, false)
  //   }
  // })
  onUnmounted(() => {
    dp.value.fullScreen.cancel('web')
    window.location.reload(true)
  })
})
</script>

<style lang="scss" scoped>
.playerPage {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1% 5% 1% 5%;
  margin-top: 0%;
  margin-bottom: 0%;
  .mobpage {
    margin-top: 2%;
    display: flex;
    @media (max-width: 600px) {
      margin-top: 10%;
    }
  }
}
.pcard {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0;
}
.plist {
  border-bottom-style: dotted;
  border-color: #465d83;
  border-width: 1px;
  margin: 0;
  width: 100%;
}
p {
  margin: 2px 0 2px 0;
  line-height: 1.8;
}
#dplayer {
  height: v-bind(DpHeight);
  width: v-bind(DpWidth);
}
.player ::v-deep(.dplayer-menu-show) {
  display: none;
}
</style>
