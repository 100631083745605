import { createStore } from 'vuex'

import { decrypt, encrypt } from '../utils/myAes.js'

// AES加密，decrypt解密，encrypt加密
// 解密结果需要进一步JSON.parse，写在了应用层未写入stroe

export default createStore({
  state: {
    MDB: '',
    mlist: '',
    tvlist: '',
    tvnow: {}
  },
  getters: {},
  mutations: {
    setMDB(state, status) {
      state.MDB = status
      window.sessionStorage.MDB = encrypt(status)
    },
    setmlist(state, status) {
      state.mlist = status
      window.sessionStorage.mlist = encrypt(status)
    },
    settvlist(state, status) {
      state.tvlist = status
      window.sessionStorage.tvlist = encrypt(status)
    },
    settvnow(state, status) {
      state.tvnow = status
      window.sessionStorage.tvnow = encrypt(status)
    }
  },
  actions: {
    loadMDB({ commit }) {
      const loadMDB = JSON.parse(decrypt(window.sessionStorage.MDB))
      commit('setMDB', loadMDB)
    },
    loadmlist({ commit }) {
      const loadmlist = JSON.parse(decrypt(window.sessionStorage.mlist))
      commit('setmlist', loadmlist)
    },
    loadtvlist({ commit }) {
      const loadtvlist = JSON.parse(decrypt(window.sessionStorage.tvlist))
      commit('settvlist', loadtvlist)
    },
    loadtvnow({ commit }) {
      const loadtvnow = JSON.parse(decrypt(window.sessionStorage.tvnow))
      commit('settvnow', loadtvnow)
    }
  },
  modules: {}
})
