const CryptoJS = require('crypto-js')

const SECRET_KEY = CryptoJS.enc.Utf8.parse('au7745964@qq.com')
const SECRET_IV = CryptoJS.enc.Utf8.parse('plkjmnhygvftcxik')
//  加密方法
//  @param data
//  @returns {string}

export function encrypt(data) {
  if (typeof data === 'object') {
    // eslint-disable-next-line no-param-reassign
    data = JSON.stringify(data)
  }
  const dataHex = CryptoJS.enc.Utf8.parse(data)
  const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString()
}

// 解密方法
// @param data
// @returns {string}

export function decrypt(data) {
  const encryptedHexStr = CryptoJS.enc.Hex.parse(data)
  const str = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}
