<template>
  <ul class="box">
    <li
      v-for="(item, index) in props.tvinfo"
      :key="index"
      :item="item"
      class="episode"
      @click="play(index, item)"
    >
      {{ index }}
    </li>
  </ul>
</template>

<script setup>
// import { ref } from 'vue'
// import store from '@/store/index.js'
const props = defineProps({
  tvinfo: Object
})
// 把点击信息发回父组件
const emit = defineEmits(['play'])
const play = (index, item) => {
  emit('play', index, item)
}
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgb(81, 89, 100);
}
::-webkit-scrollbar-track {
  background: rgba(30, 41, 59.06);
}
.box {
  width: 100%;
  overflow-x: auto;
  display: block;
  white-space: nowrap;
  padding: 0;
}
.episode {
  margin: 0 5px 10px 5px;
  display: inline-block;
  border-style: solid;
  border-color: #2c3c56;
  height: 25px;
  line-height: 25px;
  width: 90px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  border-radius: 10px;
}
.episode:hover {
  border-color: rgba(137, 141, 102, 0.76);
  cursor: pointer;
}
</style>
