<template>
  <MyNavbar :now="now" :back="back" />
  <div v-if="show" class="home">
    <MyWaterfall class="wf" :mvarr="mvarr">
      <template v-slot="scope">
        <MyCard class="mlist" :arr="scope.mvarrMin" :item="scope.item" />
      </template>
    </MyWaterfall>
  </div>
  <div v-else>
    <LoadingPage />
  </div>
  <p v-if="over" class="contact">侵删aonini666@gmail.com</p>
</template>

<script setup>
import { ref, watchEffect, onMounted, onUnmounted } from 'vue'
import store from '@/store/index.js'
import axios from 'axios'
import MyCard from '@/components/MyCard.vue'
import MyWaterfall from '@/components/MyWaterfall.vue'
import MyNavbar from '@/components/MyNavbar.vue'
import LoadingPage from './LoadingPage.vue'
const mvarr = ref()
const id = ref('')
const show = ref(false)
const back = ref(false)
const now = ref(true)
const over = ref(false)
let timer = null

// 获取电影列表
const getList = () => {
  if (id.value !== undefined) {
    axios
      // .get('http://192.168.3.254:4510/api/list', {
      .get('https://v.189289.xyz:1122/get/api/list', {
        params: {
          id: id.value._id
        }
      })
      .then((res) => {
        mvarr.value = res.data
        store.commit('setmlist', res.data)

        id.value = res.data[res.data.length - 1]
      })
      .catch((err) => {
        throw err
      })
  } else {
    over.value = true
  }
}

// 滚动事件,触底请求数据,返回顶部锚点
const scrollfn = () => {
  if (timer) {
    clearTimeout(timer)
  }
  timer = setTimeout(() => {
    // 获取滚动时的高度
    const st = document.documentElement.scrollTop || document.body.scrollTop
    if (st > 100) {
      back.value = true
    } else {
      back.value = false
    }
    // 浏览器窗口文档的可是高度（windowHeight）,肉眼可见全屏范围
    const winHeight =
      document.documentElement.clientHeight || document.body.clientHeight
    // 文档的真实高度
    const docHeight =
      document.documentElement.scrollHeight || document.body.scrollHeight
    // console.log(st, winHeight, docHeight)
    // srolltop变量（st） + winHeight >= docHeight 即为触底
    if (st + winHeight >= docHeight / 1.4) {
      getList()
    }
  }, 100)
}

onMounted(() => {
  getList()
  window.addEventListener('scroll', scrollfn)
})
onUnmounted(() => {
  window.removeEventListener('scroll', scrollfn)
})

watchEffect(() => {
  // 请求获取前的骨架屏
  if (mvarr.value) {
    show.value = true
  } else {
    show.value = false
  }
  scrollfn()
})
</script>

<style lang="scss" scoped>
.home {
  padding: 1% 5% 4% 5%;
  // font-size: 14px;
  @media (max-width: 600px) {
    font-size: 10px;
  }
  .wf {
    margin-top: 3%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 600px) {
      margin-top: 10%;
    }
  }
  .mlist {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 18.5%;
    max-width: 18.5%;
    padding: 9px;
    @media (max-width: 600px) {
      margin-top: 1%;
      min-width: 30%;
      max-width: 30%;
      padding: 5px;
    }
  }
}
.contact {
  height: 12px;
  font-size: 12px;
  color: #4b5970;
  text-align: center;
  margin-top: -3%;
}
</style>
