<template>
  <div class="wf">
    <slot
      v-for="(item, index) in mvarrMin"
      :key="index"
      :item="item"
      :mvarrMin="mvarrMin"
    ></slot>
  </div>
</template>

<script setup>
import { ref, watchEffect, onMounted } from 'vue'
const props = defineProps({
  mvarr: Array
})
const mvarrMin = ref([])
watchEffect(() => {
  mvarrMin.value = mvarrMin.value.concat(props.mvarr)
})

onMounted(() => {
  // 屏蔽选择元素上的右键菜单
  document.querySelector('.wf').oncontextmenu = () => {
    return false
  }
})
</script>

<style lang="scss" scoped></style>
